import {
  BindGuestUserParams,
  CreateUserRequestParams,
  DatabaseStorePayload,
  DeleteAccountParams,
  Entitlement,
  GenerateCsvRequestParams,
  GeneratePDFRequestParams as GeneratePdfRequestParams,
  GetCurrentUserRequestParams,
  GetPointsForSegmentRequestParams,
  GetPointsForSegmentsRequestParams,
  GetRoutesRequestParams,
  GetRoutesStatsRequestParams,
  GetSegmentsForRouteRequestParams,
  GetUserEntitlementAttributionOwnedBy,
  GetUserEntitlementOwner,
  GetUserEntitlementsByEmailRequestParams,
  GetUserEntitlementsRequestParams,
  GetUserQuotaRequestParams,
  Product,
  Quota,
  RoutePayload,
  RouteStatsPayload,
  SegmentPayload,
  SegmentPointsPayload,
  SegmentsPointsPayload,
  SendPasswordResetRequestParams,
  SendTransactionRequestParams,
  SendVerificationRequestParams,
  StoreInitializerRequestParams,
  UserEntitlementOwner,
  UserEntitlementsAttributions,
  UserEntitlementsPayload,
  UserEntitlementsPayloadWithUserId,
  UserPayload,
  VerifyMailRequestParams,
} from '../models';
import { AuthenticationService } from './AuthenticationService';

export class CloudFetchService {
  private static API_DOMAIN = '';

  public static setDomain(domain: string) {
    CloudFetchService.API_DOMAIN = domain;
  }

  private static async apiFetch<ReturnType>(
    method: 'GET' | 'POST',
    endpoint: string,
    options?: { body?: unknown; queryParams?: Record<string, string> }
  ) {
    const API_DOMAIN = CloudFetchService.API_DOMAIN;
    const API_PREFIX = `api/v1`;
    const token = await AuthenticationService.getAuthenticatedUser()?.getIdToken();

    const queries = options?.queryParams !== undefined ? new URLSearchParams(options.queryParams).toString() : '';

    const httpOptions: RequestInit = { method };

    if (token !== undefined) {
      httpOptions.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    if (options?.body !== undefined) {
      httpOptions.body = JSON.stringify(options.body);

      if (httpOptions.headers === undefined) {
        httpOptions.headers = {};
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      httpOptions.headers['Content-Type'] = 'application/json';
    }

    const url = `${API_DOMAIN}/${API_PREFIX}/${endpoint}?${queries}`;

    return fetch(url, httpOptions).then(async res => {
      if (!res.ok) {
        throw await res.json();
      }

      return res.json().catch(err => {
        console.log(`[JSON ERROR] Fail to parse content to json (${url})`, err);
        return undefined;
      }) as unknown as ReturnType;
    });
  }

  static async sendTransaction(params: SendTransactionRequestParams): Promise<void> {
    return CloudFetchService.apiFetch<void>('POST', 'transactions', {
      body: params,
    });
  }

  static async bindGuestUser(params: BindGuestUserParams) {
    return CloudFetchService.apiFetch<UserPayload>('POST', 'auth/bind', {
      body: {
        userId: params.userId,
        firstName: params.firstName,
        lastName: params.lastName,
        email: params.email,
      },
    });
  }

  static async deviceGuestAccount(deviceId: string) {
    return CloudFetchService.apiFetch<Pick<UserPayload, 'email'> & { password: string }>(
      'POST',
      'auth/device-guest-account',
      {
        body: {
          deviceId,
        },
      }
    );
  }

  static async getCustomToken(userId: string) {
    return CloudFetchService.apiFetch<{ token: string }>('GET', 'auth/custom-token', {
      queryParams: { userId },
    });
  }

  static async createUser(params: CreateUserRequestParams) {
    return CloudFetchService.apiFetch<UserPayload>('POST', 'auth/create', {
      body: {
        email: params.email,
        password: params.password,
        firstName: params.firstName,
        lastName: params.lastName,
      },
    });
  }

  static async resendVerificationEmail(params: SendVerificationRequestParams) {
    return CloudFetchService.apiFetch<void>('POST', 'auth/resend-verify', {
      body: {
        userId: params.userId,
      },
    });
  }

  static async verifyEmail(params: VerifyMailRequestParams) {
    return CloudFetchService.apiFetch<void>('POST', 'auth/verify', { body: { oobCode: params.oobCode } });
  }

  static async sendPasswordResetEmail(params: SendPasswordResetRequestParams) {
    return CloudFetchService.apiFetch<void>('POST', 'auth/password-reset', { body: { email: params.email } });
  }

  static async getCurrentUser(params: GetCurrentUserRequestParams) {
    return CloudFetchService.apiFetch<UserPayload>('GET', `users/${params.userId}`);
  }

  static async getUserQuota(params: GetUserQuotaRequestParams): Promise<Quota> {
    return CloudFetchService.apiFetch<Product>('GET', `products/${params.productId ?? 'free'}`).then(product => ({
      segments: {
        count: product.metadata.allowedSegmentCount === 'undefined' ? undefined : +product.metadata.allowedSegmentCount,
        unit: product.metadata.allowedSegmentUnit,
      },
    }));
  }

  static async getRoutes(params: GetRoutesRequestParams): Promise<RoutePayload[]> {
    return CloudFetchService.apiFetch('GET', `routes`, {
      queryParams: {
        userId: params.userId,
        startAt: new Date(params.startAt).toISOString(),
        endAt: new Date(params.endAt).toISOString(),
        lastFetchedDate: new Date(params.lastFetchedDate).toISOString(),
        limit: params.limit.toString(),
        withStats: 'true',
      },
    });
  }

  static async getRoutesStats(params: GetRoutesStatsRequestParams): Promise<RouteStatsPayload> {
    return CloudFetchService.apiFetch<RouteStatsPayload>('GET', 'routeStats', {
      queryParams: {
        userId: params.userId,
        startAt: new Date(params.startAt).toISOString(),
        endAt: new Date(params.endAt).toISOString(),
      },
    });
  }

  static async getSegmentsForRoute(params: GetSegmentsForRouteRequestParams): Promise<SegmentPayload[]> {
    return CloudFetchService.apiFetch<SegmentPayload[]>('GET', 'segments', {
      queryParams: {
        userId: params.userId,
        routeId: params.routeId,
      },
    });
  }

  static async getPointsForSegment(params: GetPointsForSegmentRequestParams): Promise<SegmentPointsPayload> {
    return CloudFetchService.apiFetch<SegmentsPointsPayload[]>('GET', 'points', {
      queryParams: {
        userId: params.userId,
        routeId: params.routeId,
        segmentIds: params.segmentId,
      },
    }).then(res => res[0]);
  }

  static async getPointsForSegments(params: GetPointsForSegmentsRequestParams): Promise<SegmentsPointsPayload[]> {
    return CloudFetchService.apiFetch<SegmentsPointsPayload[]>('GET', 'points', {
      queryParams: {
        userId: params.userId,
        routeId: params.routeId,
        segmentIds: params.segmentIds.join(','),
      },
    });
  }

  // Generate a CSV with the segments in the given range, and return the file path to where it is saved in the storage
  static async generateCsv(params: GenerateCsvRequestParams): Promise<string> {
    const queryParams = {
      format: 'csv',
      type: params.type,
      delimiter: params.delimiter ?? ';',
      userId: params.userId,
      vehicleId: params.vehicleId,
      startAt: new Date(params.startDateString).toISOString(),
      endAt: new Date(params.endDateString).toISOString(),
    };

    return CloudFetchService.apiFetch('GET', 'exports/report', { queryParams });
  }
  // Generate a PDF with the segments in the given range, and return the file path to where it is saved in the storage
  static async generatePdf(params: GeneratePdfRequestParams): Promise<string> {
    const queryParams = {
      format: 'pdf',
      type: params.type,
      userId: params.userId,
      vehicleId: params.vehicleId,
      startAt: new Date(params.startDateString).toISOString(),
      endAt: new Date(params.endDateString).toISOString(),
    };

    return CloudFetchService.apiFetch('GET', 'exports/report', { queryParams });
  }

  static async deleteAccount(params: DeleteAccountParams): Promise<string> {
    return CloudFetchService.apiFetch('POST', 'auth/delete', { body: { userId: params.userId } });
  }

  static async getStoreState(params: StoreInitializerRequestParams) {
    return CloudFetchService.apiFetch<DatabaseStorePayload>('GET', `store/${params.userId}`);
  }

  static async getUserEntitlement(params: GetUserEntitlementsRequestParams) {
    return CloudFetchService.apiFetch<UserEntitlementsPayload>('GET', `auth/entitlement`, {
      queryParams: {
        userId: params.userId,
      },
    });
  }

  static async getUserEntitlementByEmail(params: GetUserEntitlementsByEmailRequestParams) {
    return CloudFetchService.apiFetch<UserEntitlementsPayloadWithUserId>('GET', `entitlements/user`, {
      queryParams: {
        email: params.email,
      },
    });
  }

  static async getUserEntitlementAttributionOwnedBy(params: GetUserEntitlementAttributionOwnedBy) {
    return CloudFetchService.apiFetch<UserEntitlementsAttributions>('GET', `entitlements/attributions`, {
      queryParams: {
        userId: params.userId,
      },
    });
  }

  static async getUserEntitlementOwner(params: GetUserEntitlementOwner) {
    return CloudFetchService.apiFetch<UserEntitlementOwner>('GET', `entitlements/owner`, {
      queryParams: {
        userId: params.userId,
      },
    });
  }

  static async getAllEntitlements() {
    return CloudFetchService.apiFetch<{ entitlements: { [entitlementId: string]: Entitlement } }>(
      'GET',
      `entitlements`
    );
  }
}
